.st0-jun-breed {
  fill: #ffc54d;
}
.st1-jun-breed {
  fill: #edb24a;
}
.st2-jun-breed {
  fill: #5195ff;
}
.st3-jun-breed {
  fill: #f46262;
}

.st0-jun-country {
  fill: #ffc54d;
}
.st1-jun-country {
  fill: #c19d72;
}
.st2-jun-country {
  fill: #a88763;
}

.st0-breed {
  fill: #e24255;
}
.st1-breed {
  fill: #ffc54d;
}
.st2-breed {
  fill: #e8b04b;
}
.st3-breed {
  fill: #ffffff;
}
