@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'tailwindcss/utilities';

body {
  -moz-osx-font-smoothing: grayscale;

  background-size:
    auto auto,
    auto auto,
    32px 32px,
    80px 80px,
    50px 50px,
    70px 70px;

  background-position:
    10% 40%,
    calc(30% + 20px) calc(20% - 20px),
    calc(70% - 10px) calc(15% + 10px),
    calc(80% - 10px) calc(25% + 10px),
    calc(95% - 10px) calc(60% + 10px),
    calc(80% - 10px) calc(80% + 10px),
    calc(15% - 10px) calc(74% + 10px);
}

/* Исправление размера текста для определенных устройств */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

.custom-bar {
  background: rgb(19 187 169);
  position: fixed;
  z-index: 1600;
  top: 80px;
  left: 0;
  width: 100%;
  height: 4px;
}
